
:deep(.v-list-item__title) {
    line-height: 14px !important;
}
.w-15 {
    width: 15%;
}
.v-data-table {
    margin-top: 20px;
    border-top: 2px solid var(--v-grey-darken4);
    :deep(table) {
        thead {
            display: none;
            th {
                text-align: center !important;
            }
        }
        tbody {
            tr {
                display: flex;
                flex-wrap: wrap;
                border-bottom: 1px solid var(--border-color);
                background-color: transparent !important;
                cursor: pointer;
                td {
                    height: auto !important;
                    padding: 12px !important;
                    border-bottom: none !important;
                    position: relative;
                    &:first-child {
                        width: 100%;
                    }
                    &:nth-child(2),
                    &:nth-child(3) {
                        &::after {
                            content: "";
                            position: absolute;
                            right: 0;
                            top: 50%;
                            transform: translateY(-50%);
                            width: 1px;
                            height: 12px;
                            background-color: var(--border-color);
                        }
                    }
                }
            }
        }
    }
}
@media (min-width: 768px) {
    .v-data-table {
        :deep(table) {
            thead {
                display: table-header-group;
            }
            tbody {
                tr {
                    display: table-row;
                }
                td {
                    padding: 16px 20px !important;
                    &:first-child {
                        width: auto !important;
                    }
                    &:nth-child(2),
                    &:nth-child(3) {
                        &::after {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}
