
.board-view {
    border-top: 2px solid var(--v-grey-darken4);
    &__content {
        img {
            display: block;
            max-width: 100%;
            height: auto;
        }
    }
}
